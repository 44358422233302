import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as QliroCheckoutName } from '@spa-ec/components/QliroCheckout/QliroCheckout.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as FooterName } from '@spa-ec/components/Footer/Footer.theme';
import { NAME as ReplacementTableName } from '@spa-ec/components/ReplacementHelper/ReplacementTable.theme';
import { NAME as USPWrapperName } from '@spa-ec/components/ContentSlot/components/USPWrapper/USPWrapper.theme';
import { NAME as SiteLogoName } from '@spa-ec/components/Header/HeaderSiteLogo/SiteLogo.theme';
import { NAME as PrisjaktCheapestName } from '@spa-ec/components/PrisjaktCheapest/PrisjaktCheapest.theme';
import { NAME as ShipmentAddressFormName, } from '@spa-ec/components/Checkout/AddressSection/ShipmentAddressForm.theme';
import theme from './theme.conf';
const contentPageTheme = {
    showSideNavigationOnStaticPages: true,
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
    linkColor: theme.col.secondary.fg,
    linkColorHover: theme.col.secondary.peer,
    sideContentPadding: 'px-4',
};
const qliroCheckoutTheme = {
    primaryColor: theme.col.primary.fg,
    callToActionColor: theme.col.primary.fg,
    callToActionHoverColor: theme.col.primary.peer,
};
const pageTitleTheme = {
    noBorder: true,
    showLogoutIcon: false,
    combineSections: true,
};
const cartTableTheme = {
    showProductDetails: true,
    showPerKronaInCartTable: false,
};
const footerTheme = {
    showNewsLetter: false,
};
const replacementTableTheme = {};
const uspWrapperTheme = {
    maximumItemsIpad: 2,
};
const shipmentAddressFormTheme = {
    showShippingAddressCellphone: true,
};
const prisjaktCheapestTheme = {
    storeIds: { 'inky-se': 5543, 'inky-no': 23420, 'inky-fi': 33599, 'inky-dk': 36436 },
};
const siteLogoTheme = {
    siteLogo: {
        imagesRootPath: '/images/sitelogo',
        paths: {
            b2c: {
                se: {
                    sv: '/inky_logo_se_v2.svg',
                },
                dk: {
                    da: '/inky_logo_white_dk_v3.svg',
                },
                fi: {
                    fi: '/inky_logo_white_fi_v3.svg',
                },
                no: {
                    no: '/inky_logo_white_no_v3.svg',
                },
                default: '/inky_logo_se_v2.svg',
            },
            b2b: {
                default: '/inky_logo_se_v2.svg',
            },
        },
    },
};
export default {
    [ContentPageName]: contentPageTheme,
    [QliroCheckoutName]: qliroCheckoutTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [FooterName]: footerTheme,
    [ReplacementTableName]: replacementTableTheme,
    [USPWrapperName]: uspWrapperTheme,
    [PrisjaktCheapestName]: prisjaktCheapestTheme,
    [SiteLogoName]: siteLogoTheme,
    [ShipmentAddressFormName]: shipmentAddressFormTheme,
};
